<template>
  <div class="hello">
 <div class="servicios-relacionados ">
    <div class="row">
          <div class="  col-md-4 relacionados" v-for="(servicio, item) of tasks" :key="item">
            <div class="card">
                <div class="card-body fondo-relacionados">
                    <h2 class="titulos" v-text="servicio.title"></h2>
                </div>
                <img class="img-fluid" :src="servicio.imagen.img" :alt="servicio.imagen.alt" >
                <div class="card-body fondo-relacionados">
                    <p class="card-text" v-text="servicio.text"></p>
                </div>
                <div class="card-footer fondo-relacionados"> <a class="btn btn-primary" :href="servicio.link.href">{{ servicio.link.text }}</a></div>
            </div>
        </div>
        </div>
    </div>
     </div>
</template>
<script>

export default {
  name: 'Relacionados',
  props: {
  },
  data () {
    return {
      tasks: [
        {
          title: 'organizacion de Racks',
          text: 'Organizamos y marcamos de forma tecnica todo su rack de comunicaciones que es parte fundamental de su negocio evite inconvenientes y detecte y corrija fallos mas facilmentecon un rack ordenado.',
          imagen: { alt: 'Vue logo', img: '/images/puntos-red-voz-y-datos.webp' },
          link: {
            href: 'reparacion-portatiles-bogota.html',
            class: 'boton-contacto-soporte-card',
            text: 'Mas informacion'
          }
        },
        {
          title: 'Tecnicos Telefonia',
          text: 'Solucionamos problemas frecuentes con su telefonía análoga e IP líneas telefónicas, extensiones. Programación de conmutadores y centrales IP.',
          imagen: { img: '/images/conmutador-telefonico-bogota.png', alt: 'mantenimiento y reparacion' },
          link: {
            href: 'instalacion-mantenimiento-cctv-bogota.html',
            class: 'boton-contacto-soporte-card',
            text: 'Mas informacion'
          }
        }, {
          title: 'Camaras seguridad',
          text: 'Hacemos que su sistema de cámaras de seguridad trabaje sin contratiempos que su casa o negocio estén siempre supervisados por Internet. Servicio de instalación y mantenimiento de cámaras.',
          imagen: { img: '/images/reparacionportatiles.jpg', alt: 'mantenimiento y reparacion Cmaras de seguridad' },
          link: {
            href: 'instalacion-mantenimiento-cctv-bogota.html',
            class: 'boton-contacto-soporte-card',
            text: 'Mas informacion'
          }
        },
        {
          title: 'Configurar camaras',
          text: 'Si tiene su sistema de cámaras de seguridad y no funcionan correctamente nosotros le brindamos la asistencia técnica para sus cámaras de su casa o negocio estén bien configuradas y verlas por Internet.',
          imagen: { img: '/images/Mantenimiento-camaras.png', alt: 'mantenimiento y reparacion camaras de vigilancia' },
          link: {
            href: 'https://www.cmlabtec.com/blog/configuracion-dvr-bogota',
            class: 'boton-contacto-soporte-card',
            text: 'Mas informacion'
          }
        },
        {
          title: 'Recuperar contraseña DVR NVr',
          text: 'Si olvido su contraseña del sistema de cámaras de seguridad y no puede ingresar brindamos la asistencia técnica para sus cámaras de su casa o negocio estén bien configuradas y verlas por Internet.',
          imagen: { img: '/images/password-dvr-camaras.png', alt: 'resetear clave dvr Bogota' },
          link: {
            href: 'https://www.cmlabtec.com/blog/recuperacion-contrasenas-dvr-bogota/',
            class: 'boton-contacto-soporte-card',
            text: 'Mas informacion'
          }
        },
        {
          title: 'Redes datos voz',
          text: 'Adecuación de redes de voz y datos hacemos que todos sus equipos y dispositivos estén conectados y trabajando en red, optimizamos toda la red de su empresa seguridad, confiabilidad y disponibilidad. Revisión e instalación. ',
          imagen: { img: '/images/redes_datos.gif', alt: 'Fallas red de internet' },
          link: {
            href: 'mantenimiento-redes-vozydatos.html',
            class: 'boton-contacto-soporte-card',
            text: 'Mas informacion'
          }
        },
        {
          title: 'SOPORTE REMOTO',
          text: 'Soporte remoto servicio tecnico efectivo sin salir de casa rapido y efectivo solucion de problemas de configuracion, rendimiento y virus con sus equipos. ',
          imagen: { img: 'images/teamviewer_logo.jpg', alt: 'Solucion fallos computador remotamente' },
          link: {
            href: 'https://www.cmlabtec.com/soporte-remoto-bogota.html',
            class: 'boton-contacto-soporte-card',
            text: 'Mas informacion'
          }
        },
        {
          title: 'PUNTOS DE RED ',
          text: 'Instalamos nuevos puntos de conexion a su red de datos y voz para adiccionar y/o modificar puestos de trabajo en su empresa.',
          imagen: { img: 'images/puntos-red-voz-y-datos.png', alt: 'Instalacion puntos de red' },
          link: {
            href: 'https://www.cmlabtec.com/redes-voz-datos-bogota.html',
            class: 'boton-contacto-soporte-card',
            text: 'Mas informacion'
          }
        }, {
          title: 'FORMATEAR COMPUTADOR  ',
          text: 'Deje su computador como nuevo reinstalándole de nuevos todos los programas Windows y aplicaciones de trabajo como Word Excel etc.',
          imagen: { img: 'images/formatear-computador-Bogota.webp', alt: 'Formatear computadores Bogota' },
          link: {
            href: 'https://www.cmlabtec.com/formatear-computador.html',
            class: 'boton-contacto-soporte-card',
            text: 'Mas informacion'
          }
        },
        {
          title: 'SERVICIO DE URGENCIAS ',
          text: 'Sufrió algún accidente con su computador, no enciende necesita la información urgente. Nosotros le brindamos el servicio tecnico extra rápido de enviarle un tecnico que resuelva su problema.',
          imagen: { img: '/images/servicio-tecnico-a-domicilio.png', alt: 'Servicio de urgencias para computadores 24 horas ' },
          link: {
            href: 'https://www.cmlabtec.com/blog/solicitar-presupuesto/',
            class: 'boton-contacto-soporte-card',
            text: 'Solicitar'
          }
        }, {
          title: 'PROGRAMACION CENTRALES ',
          text: 'Disfrute de todos los servicios que tiene su planta telefonica nuestro servicio tecnico configura y programara todas la funciones de su central.',
          imagen: { img: '/images/programacion-y-configuracion-conmutador-telefonico.jpg', alt: 'Programacion y arreglo Centrales telefonicas ' },
          link: {
            href: 'https://www.cmlabtec.com/blog/solicitar-presupuesto/',
            class: 'boton-contacto-soporte-card',
            text: 'Solicitar'
          }
        }, {
          title: 'URGENCIAS TELEFONICAS',
          text: 'No tener los sistemas de comunicación en su empresa puede hacerle perder dinero y clientes. Solucionamos las fallas con sus líneas telefónicas y extensiones en el menor tiempo con nuestro personal a domicilio.',
          imagen: { img: '/images/servicio-tecnico-a-domicilio.png', alt: 'tecnicos  en telefonos bogot' },
          link: {
            href: 'https://www.cmlabtec.com/blog/solicitar-presupuesto/',
            class: 'boton-contacto-soporte-card',
            text: 'Solicitar'
          }
        },
        {
          title: 'Mantenimiento correctivo ',
          text: 'Al realizar un mantenimiento correctivo logra solucionar fallas comunes que impiden que su computador trabaje sin problemas, Acelera el rendimiento; Previene daños mayores y perdida de información.',
          imagen: { img: 'images/mantenimiento-preventivo-computadores.jpg', alt: 'Mantenimiento y reparacion computadores Bogota' },
          link: {
            href: 'mantenimiento-correctivo-bogota.html',
            class: 'boton-contacto-soporte-card',
            text: 'Solicitar Mantenimiento'
          }
        },
        {
          title: 'Mantenimiento Preventivo ',
          text: 'Si su computador trabaja bien, lo recomendable es hacer un mantenimiento periódico para revisar que todo está trabajando bien, Prevenga inconvenientes perdida de información y tiempo por daños.',
          imagen: { img: '/images/computador-sin-mantenimiento.webp', alt: 'Mantenimiento y reparacion computadores Bogota' },
          link: {
            href: 'mantenimiento-correctivo-bogota.html',
            class: 'boton-contacto-soporte-card',
            text: 'Solicitar Mantenimiento'
          }
        },
        {
          title: 'Reparación Portátiles ',
          text: 'Solucionamos los daños con su computador portátil físicos y lógicos Reparamos su computadora portátil todas las marcas cualquier falla se puede solucionar servicio a domicilio.',
          imagen: { img: 'images/reparacionportatiles.jpg', alt: 'Reparacion computadores  Portatiles Bogota' },
          link: {
            href: 'reparacion-portatiles-bogota.html',
            class: 'boton-contacto-soporte-card',
            text: 'Reparar'
          }
        }

      ],
      edit: false,
      taskToEdit: ''
    }
  }
}
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #ffffff;
}
.servicios-relacionados { padding-top: 30px; text-align: center; display: -webkit-box; display: -ms-flexbox; display: flex; -ms-flex-wrap: wrap; flex-wrap: wrap;  color:#f9f9f9 }
.relacionados { height: auto; float: left; text-align: center; padding: 1rem; display: flex; }
.relacionados h4 { font-size: 1.2rem; }
p .titulos { color: #fff; font-weight: 200; margin: 2px; text-transform: uppercase; }
.titulos { color: #fff; font-weight: 200; margin: 5px; text-transform: uppercase; }
.fondo-relacionados{ background-color: black;}
</style>
