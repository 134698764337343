<template>
<div class="hello">
<div class="texto-encabezado text-center bienvenidos">
<div class="container">
<p class="display-4 wow bounceIn">Soporte Tecnico Sistemas</p>
<p class="wow bounceIn" data-wow-delay=".3s">Servicio de Soporte Tecnico Especializado a Domicilio.</p>
<a href="https://www.cmlabtec.com/blog/contactenos-en-bogota/" class="btn btn-primary btn-lg">Contacto</a>
</div>
<div class="flecha-bajar text-center">
<a data-scroll href="#agencia" aria-hidden="true" arial-label=" mas informacion sobre reparacion de computadores en bogota"><font-awesome-icon icon="laptop" /></a>
</div>
</div>
</div>
</template>
<script>
export default {
  name: 'Encabezado',
  props: {
    msg: String
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
.bienvenidos { background: url("https://www.cmlabtec.com/images/fondo-encabezado.jpg") no-repeat center top; color: #fff; background-size: cover; background-attachment: fixed !important; height: 100vh; display: -webkit-box; display: -webkit-flex; display: -ms-flexbox; display: flex; -webkit-box-orient: vertical; -webkit-box-direction: normal; -webkit-flex-direction: column; -ms-flex-direction: column; flex-direction: column; }
.texto-encabezado { -webkit-box-flex: 1; -webkit-flex: 1 1 auto; -ms-flex: 1 1 auto; flex: 1 1 auto; display: -webkit-box; display: -webkit-flex; display: -ms-flexbox; display: flex; -webkit-box-align: center; -webkit-align-items: center; -ms-flex-align: center; align-items: center; }
.texto-encabezado .display-4 { margin-top: 4rem; }
.texto-encabezado .btn-primary { margin-top: 1rem; }
.flecha-bajar a { display: inline-block; color: #ffff; font-size: 4rem; -webkit-animation: flecha-animada 2s ease-in-out infinite; animation: flecha-animada 2s ease-in-out infinite; }
.flecha-bajar a{ color: white!important; padding: 3rem}
@-webkit-keyframes flecha-animada { from { -webkit-transform: translateY(0); transform: translateY(0); }
  50% { -webkit-transform: translateY(6px); transform: translateY(6px); }
  to { -webkit-transform: translateY(0); transform: translateY(0); } }
@keyframes flecha-animada { from { -webkit-transform: translateY(0); transform: translateY(0); }
  50% { -webkit-transform: translateY(6px); transform: translateY(6px); }
  to { -webkit-transform: translateY(0); transform: translateY(0); } }
</style>
