import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
require('./plugins/fontawesome')
require('./plugins/bootstrap')
require('./plugins/nav')
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
