<template>
  <div class="hello">
  <div class="boton-contacto">
    <a class="btn btn-primary" href="https://www.cmlabtec.com/blog/contactenos-en-bogota">Contactenos Ahora</a>
</div>
<div class="shop-features ">
    <div class="row">
        <div class="col-md-4 custom-support"><div class="iconos"><font-awesome-icon icon="home" /></div>
                   <div class="content">
                <h2>
                <span>SERVICIO AL CLIENTE</span>
                </h2>
                <span><em>Siempre con Usted</em></span>
            </div>
        </div>
        <div class="col-md-4 custom-support">
        <div class="iconos">
        <font-awesome-icon icon="credit-card" />
        </div>
                       <div class="content">
                <h2>
                <span>TODAS LAS FORMAS DE PAGO</span>
                </h2>
                <span><em>Usted elige</em></span>
            </div>
        </div>
        <div class="col-md-4 custom-support"><div class="iconos"><font-awesome-icon icon="users" /></div>
                      <div class="content">
                <h2>
                    <span>TODO EN EL MISMO LUGAR</span>
                </h2>
                <span><em>Pregúntenos</em></span>
            </div>
        </div>
    </div>
</div>
     </div>
</template>
<script>

export default {
  name: 'Shop',
  props: {
  }
}
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}

[class^="icon-"]:before, [class*=" icon-"]:before { font-family: "fontAwesome"; font-style: normal; font-weight: normal; display: inline-block; text-decoration: inherit; width: 1em; margin-right: .2em; text-align: center; /* opacity: .8; */ /* For safety - reset parent styles, that can break glyph codes*/ font-variant: normal; text-transform: none; /* fix buttons height, for twitter bootstrap */ line-height: 1em; /* Animation center compensation - margins should be symmetric */ /* remove if not needed */ margin-left: .2em; /* you can be more comfortable with increased icons size */ /* font-size: 120%; */ /* Font smoothing. That was taken from TWBS */ -webkit-font-smoothing: antialiased; -moz-osx-font-smoothing: grayscale; /* Uncomment for 3D effect */ /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */ }
.iconos{
font-size: 3rem;
padding-left: 50px;
padding-right: 50px;
color:#0d6efd;
}
.custom-support { text-align: center; padding-top: 50px ;padding-bottom: 50px; }
.custom-support .col-md-4 i { border: 0 !important; float: none; }
.custom-support i { text-align: center; color: #0088cc !important; width: auto; height: auto; border-radius: 0; padding-bottom: 14px; display: inline-block; font-size: 40px; }
.custom-support font-awesome-icon { text-align: center; color: #0088cc !important; width: auto; height: auto; border-radius: 0; padding-bottom: 14px; display: inline-block; font-size: 40px; }
.custom-support .content h2 { margin: 0; font-weight: 600; text-transform: uppercase; font-size: 16px; color: #2b2b2d; line-height: 22px; }
.icon-action-undo:before { content: '\f122'; }
.icon-earphones-alt:before { content: '\f025'; }
.icon-credit-card:before { content: '\f09d'; }
</style>
