<template>
  <div>
   <footer>
    <div class="col-md-12 pie-pagina">
        <div class="comunica">
            <div class="row">
                <div class=" col-md-4 callmefooter">
                    <div class="telefonos">
                        <img class="lazyload" src="../assets/contacto-bogota.png"
                            alt="contáctanos en Bogotá empresas y oficinas" width="57" height="52">
                        <p>Solicite Mas Información</p>
                           </div>
                    <ul class="actions">
                        <li><a class="btn btn-primary" href="tel:0317537246">PBX 753-7246</a></li>
                        <li><a class="btn btn-primary" href="tel:3118120925">CEL 311 812-0925</a></li>
                    </ul>
                </div>
                <div class=" col-md-4 callmefooter">
                    <h2 class="text-center">Síguenos en</h2>
                    <div class="imagenreds">
                        <a href="https://www.facebook.com/cmlabtec" target="_blank" rel="noopener"
                            arial-label="siguenos en facebook"></a>
                    </div>
                    <div class="imagenredsb">
                        <a href="https://twitter.com/cmlabtec" target="_blank" rel="noopener"
                            arial-label="siguenos en twitter"></a>
                    </div>
                    <div class="imagenredsc">
                        <a href="https://www.youtube.com/c/cmlaboratoriotecnicobogota" target="_blank" rel="noopener"
                            arial-label="suscribete a nuestro canal en youtube"></a>
                    </div>
                    <div class="imagenredse">
                        <a href="https://www.linkedin.com/company/cm-laboratorio-tecnico" target="_blank" rel="noopener"
                            arial-label="siguenos en linkedin"></a>
                    </div>
                </div>
                <div class=" col-md-4 callmefooter">
                    <img class="img-responsive lazyload"  src="../assets/paga-tu-pagina-web.png"
                        alt="contáctanos en Bogotá empresas y oficinas" width="90%" height="auto">
                    <p>Solicite Mas Información</p>
                </div>
            </div>
        </div>
        <div class="container">
            <div class="row">
                <div class="col-6 col-lg-3">
                    <div class="lista">
                        <p class="barrasf">HERRAMIENTAS </p>
                        <ul>
                            <li><a href="herramientas.html"
                                    title="Herramientas soporte y reparacion de  computadores">Adwcleaner</a></li>
                            <li><a href="https://www.cmlabtec.com/herramientas.html"
                                    title="Software arreglo computadores"> TeamViewer</a></li>
                            <li><a href="https://www.cmlabtec.com/herramientas.html" title="reparación pc">Ver más</a>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="col-6 col-lg-3">
                    <div class="lista">
                        <p class="barrasf">LA EMPRESA</p>
                        <ul>
                            <li><a href="https://www.cmlabtec.com/servicio-tecnico-a-empresas.html"
                                    title="soporte a empresas reparación equipos ">empresas</a></li>
                            <li><a href="https://www.cmlabtec.com/blog/quienes-somos-cmlabtec/"
                                 title="Quienes somos">Quienes somos </a></li>
                        </ul>
                    </div>
                </div>
                <div class="col-6 col-lg-3">
                    <div class="lista">
                        <p class="barrasf">MAS SERVICIOS </p>
                        <ul>
                            <li><a href="https://www.cmlabtec.com/mantenimiento-correctivo-bogota.html"
                                    title="mantenimiento computadores" rel="noopener">Mantenimiento</a></li>
                            <li><a href="https://repuestoscomputadorbogota.com/" title="visita nuestra tienda virtual"
                                    rel="noopener">Tienda</a></li>
                            <li><a href="https://www.cmlabtec.com/blog/"
                                    title="visita                   nuestro  Blog de soporte tecnico"
                                    rel="noopener">Blog</a></li>
                            <li><a href="https://www.cmlabtec.com/repuestos-computador-y-portatiles.html"
                                    title="repuestos computadores y portátiles" rel="noopener">Repuestos</a></li>
                        </ul>
                    </div>
                </div>
                <div class="col-6 col-lg-3">
                    <div class="lista">
                        <p class="barrasf">TEMAS RELACIONADOS </p>
                        <ul>
                            <li><a href="formatear-computador.html" title="formatear PC a domicilio"
                                    rel="noopener">formatear PC</a></li>
                            <li><a href="actualizar-computadores-bogota.html" title="actualizar computador"
                                    rel="noopener">actualizar computador</a></li>
                            <li><a href="aumentar-memoria-computador.html" title="aumentar memoria"
                                    rel="noopener">aumentar memoria</a></li>
                            <li><a href="cual-es-mi-ip.php" title="cuál es mi dirección ip " rel="noopener">Cual es mi
                                    ip</a></li>
                            <li><a href="todos-nuestros-servicios.html" title="Ver       nuestros servicios técnicos"
                                    rel="noopener">Ver más...</a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <div class="desarroladopor">cmlabtec.com -
            Servicio Tecnico especializado en Bogotá
            Todos los derechos Reservados © 2011-2021
            <br> Desarrollado por <a href="https://webxmes.com/" title="Posicionamiento paginas web seo Colombia"
                target="_blank" rel="opener">webxmes.com</a>
        </div>
        </div>
</footer>
    </div>

</template>

<script>
export default {
  name: 'Footer',
  props: {
    msg: String
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
footer { display: -webkit-box; display: -ms-flexbox; background-color: #000000; -ms-flex-wrap: wrap; flex-wrap: wrap; }
.contactofooter { margin: 0; -webkit-box-flex: 1; -ms-flex: 1 1 50%; flex: 1 1 50%; text-decoration: none; -ms-flex-wrap: wrap; flex-wrap: wrap; -webkit-box-pack: center; -ms-flex-pack: center; justify-content: center; }
.telefonos { padding: 1rem; text-align: center; font-size: 1.2em; }
.comunica { align-content: space-between; flex: 1 1 100%; }
.callmefooter { height: auto; color: #dededd; text-decoration: none; text-align: center; padding-top: 1rem; padding-bottom: 1rem; }
.actions li { list-style: none; padding: 1rem 0; }
.lista { float: left; height: auto; font-size: 14px; text-transform: capitalize; color: #FFF; text-decoration: none; text-align: left; list-style-type: none; }
.lista li a:hover { text-decoration: underline; }
.barrasf { font-weight: 700; text-align: center; }

.desarroladopor { color: #fff; height: auto; text-align: center; padding-bottom: 2rem; width: 100%; }

.pie-de-pagina { background-color: #11689d; text-align: center; color: #fff; position: fixed; right: 0; bottom: 0; z-index: 10; }

.redes-sociales { display: -webkit-box; display: -ms-flexbox; display: flex; -ms-flex-item-align: center; align-self: center; }

.imagenreds { float: right; position: static; background: url("https://www.cmlabtec.com/css/images/redes-sociales/cmlabtec-facebook.png") no-repeat; padding-right: 15px; }

.imagenreds a { float: right; position: static; width: 32px; height: 34px; padding-right: 15px; }

.imagenredsa { background: url("https://www.cmlabtec.com/css/images/redes-sociales/cmlabtec-google.png") no-repeat; padding-right: 15px; }

.imagenredsa a { float: right; position: static; width: 32px; height: 34px; padding-right: 15px; }

.imagenredsb { float: right; position: static; background: url("https://www.cmlabtec.com/css/images/redes-sociales/cmlabtec-twitter.png") no-repeat; padding-right: 15px; }

.imagenredsb a { float: right; position: static; width: 32px; height: 34px; padding-right: 15px; }

.imagenredsc { float: right; position: static; background: url("https://www.cmlabtec.com/css/images/redes-sociales/cmlabtec-youtube.png") no-repeat; padding-right: 15px; }

.imagenredsc a { float: right; position: static; width: 32px; height: 34px; padding-right: 15px; }

.imagenredse { float: right; position: static; height: 34px; background: url("https://www.cmlabtec.com/css/images/redes-sociales/cmlabtec-linkedin.png") no-repeat; padding-right: 15px; }

.imagenredse a { float: right; position: static; height: 34px; width: 32px; padding-right: 15px; }

.servicios-relacionados { padding-top: 30px; text-align: center; display: -webkit-box; display: -ms-flexbox; display: flex; -ms-flex-wrap: wrap; flex-wrap: wrap; }

.relacionados { height: auto; float: left; text-align: center; padding: 1rem; display: flex; }

.relacionados h4 { font-size: 1.2rem; }

.titulos { color: #fff; font-weight: 600; margin: 10px; text-transform: uppercase; }
</style>
