<template>
  <div id="app" class="container">
  <Nombre />
  <Navigation />
  <router-view/>
  <Footer />
  </div>
  </template>
<script>
import Nombre from './components/NombreHeader.vue'
import Navigation from './components/Navigation.vue'
import Footer from './components/Footer.vue'

export default {
  components: {
    Nombre,
    Navigation,
    Footer
  }
}
</script>
<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}
#nav a.router-link-exact-active {
  color: #42b983;
}
.bienvenidos { background: url("https://www.cmlabtec.com/images/fondo-encabezado.jpg") no-repeat center top; color: #fff; background-size: cover; background-attachment: fixed !important; height: 100vh; display: -webkit-box; display: -webkit-flex; display: -ms-flexbox; display: flex; -webkit-box-orient: vertical; -webkit-box-direction: normal; -webkit-flex-direction: column; -ms-flex-direction: column; flex-direction: column; }
</style>
