<template>
<div>
  <b-navbar toggleable="lg" type="dark" variant="dark">
    <b-navbar-brand href="/"><img src="../assets/logo-cmlabtec-bogota.svg"  class="animated bounceIn"
        alt="Reparación  computadores  en Bogotá" width="118" height="111"></b-navbar-brand>
     <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
    <b-collapse id="nav-collapse" is-nav>
      <b-navbar-nav>
       <router-link to="/"><font-awesome-icon icon="home" /></router-link>
        <router-link to="/pruebas">Pruebas</router-link>
        <router-link to="/about">About</router-link>
        <router-link to="/portatiles"><font-awesome-icon icon="laptop" />Portatiles</router-link>
        <router-link to="/contactos">Contactenos</router-link>
      </b-navbar-nav>
      <b-navbar-nav class="ml-auto">
        <b-nav-form>
          <b-form-input size="sm" class="mr-sm-2" placeholder="Buscar"></b-form-input>
          <b-button size="sm" class="my-2 my-sm-0" type="submit">Buscar</b-button>
        </b-nav-form>
        <b-nav-item-dropdown text="Idioma" right>
          <b-dropdown-item href="#">EN</b-dropdown-item>
          <b-dropdown-item href="#">ES</b-dropdown-item>
          </b-nav-item-dropdown>
        <b-nav-item-dropdown right>
          <!-- Using 'button-content' slot -->
          <template #button-content>
            <em>User</em>
          </template>
          <b-dropdown-item href="#">Perfil</b-dropdown-item>
          <b-dropdown-item href="#">Desconecta</b-dropdown-item>
        </b-nav-item-dropdown>
      </b-navbar-nav>
    </b-collapse>
  </b-navbar>
</div>
</template>
<script>

export default {
  name: 'Navigation',
  props: {
    msg: String
  }
}
</script>
<style scoped>
ul a {
    display: block;
    padding: .8rem 0;
    padding-right: 2rem;
    text-align: center;
    color: #fff;
    text-transform: uppercase;
    border-top: 1px solid #8c8888;
    text-decoration: none;
}
</style>
