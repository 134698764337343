<template>
<div>
<section class="agencia p-y-z" id="agencia">
<div class="container">
<div class="row">
<div class="col-md-8 col-xl-9 wow bounceIn" data-wow-delay=".3s">
<h1 class="h3 text-center text-md-left ">Reparación y Mantenimiento Computadores Bogotá</h1>
<h2>Servicio Técnico de Computadores</h2>
<p>Servicio técnico a domicilio con personal calificado que brindara un servicio eficiente en su oficina y casa.</p>
<h3>Instalación y Mantenimiento Cctv </h3>
<p>Nuestros Técnicos e ingenieros siempre disponibles para solucionar problemas con sus equipos de cómputo ya sean portátiles, pc de escritorio, servidores y redes de comunicaciones, sistemas de video vigilancia privada Cctv.</p>
</div>
<div class="col-md-4 col-xl-3 wow bounceIn" data-wow-delay=".6s">
<img src="https://www.cmlabtec.com/images/agencia.svg" alt="Soporte Tecnico">
</div>
</div>
</div>
</section>
 </div>
</template>
<script>
export default {
}
</script>
<style scoped>
.agencia { background: #2C3E50; color: #fff; padding-top: 2rem; padding-bottom: 2rem; }
.agencia img { width: 80%; margin: 0 auto; display: block; }
</style>
