<template>
 <div class="fondo-video">
     <h3 class="display-5 wow bounceIn text-center  text-muted">Mas de Nuestro Servicio Tecnico en Bogota</h3>
    <div class="seccion-video">
    <div class="video"><iframe src="https://www.youtube.com/embed/9n1K3p_J7oA?controls=0" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"  allowfullscreen></iframe>
        </div>
<div class="video">
    <iframe src="https://www.youtube.com/embed/NaY6atWsrkY" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"  allowfullscreen></iframe>
    </div>
<div class="video">
    <iframe src="https://www.youtube.com/embed/GyBNwrzz6-4" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"  allowfullscreen></iframe></div>
</div>
</div>
</template>

<script>
export default {
  name: 'Videos',
  props: {
    msg: String
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
.fondo-video { background-color: #00000012;}
.seccion-video {display: flex; flex-wrap: wrap; align-items: inherit; align-content: space-around; }
.video { align-content: space-between; flex: auto; padding-bottom: 1rem; }
</style>
