<template>
<div class="nombre">
<div class="texto">COMPUTADORES - REDES - CCTV - TELEFONIA- REPUESTOS BOGOTA </div>
<div class="telefonoscolombia"><img class="lazyload" src="images/soporte-pc-bogota.svg" alt="Reparación pc en  Bogotá"><img class="lazyload" src="images/soporte-tecnico-colombia.webp" alt="Reparación equipos portátiles en Bogotá"> <a class="btn btn-primary" href="tel:0317537246" title="Comuníquese Ahora">PBX: 753-7246</a>
</div>
</div>
</template>
<script>
export default {
}
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
.nombre { align-items: center; display: flex; flex-wrap: wrap; justify-content: space-around; text-transform: capitalize; background-color: black;padding-bottom: 2rem;padding-top: 2rem;}
.texto { display: -webkit-box; display: -ms-flexbox; display: flex; letter-spacing: 3px; text-align: center;  color: white}
.telefonoscolombia a { color: #fff; display: -webkit-box; display: -ms-flexbox; display: flex; padding: 1rem; }
.telefonoscolombia { display: -webkit-box; display: -ms-flexbox; display: flex; }
.telefonoscolombia img { padding: 0.5rem; display: -webkit-box; display: -ms-flexbox; display: flex; width: 66px; height: 59px; }
</style>
