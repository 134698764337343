<template>
  <div class="home">
  <Encabezado />
  <Agencia />
    <img alt="Vue logo" src="../assets/logo.png">
    <HelloWorld />
    <Servicios />
    <Videos />
    <Relacionados />
    <Shop />
    </div>
</template>
<script>
// @ is an alias to /src
import HelloWorld from '../components/HelloWorld.vue'
import Shop from '../components/Shop.vue'
import Relacionados from '../components/Relacionados.vue'
import Videos from '../components/Videos.vue'
import Servicios from '../components/Servicios.vue'
import Encabezado from '../components/Encabezado.vue'
import Agencia from '../components/Agencia.vue'
export default {
  name: 'Home',
  components: {
    HelloWorld,
    Shop,
    Relacionados,
    Videos,
    Servicios,
    Encabezado,
    Agencia
  }
}
</script>
