<template>
<div class="home">
<div class="row">
<div class="col-md-4" v-for="( servi , item ) of services" :key="item" >
<article class="soportes agencia ">
<h2 class="text-xs-center text-md-center" v-text="servi.title"></h2><img class="wow zoomIn" data-wow-duration=".3s" data-wow-delay=".3s" :src="servi.imagen" alt="servicio tecnico especializado en Bogotá">
<p class="text-justify pb-2 pt-5" v-html="servi.description"></p>
</article>
</div>
</div>
</div>
</template>
<script>
export default {
  name: 'Servicios',
  props: {
  },
  data () {
    return {
      services: [
        {
          title: 'Soporte',
          description: 'Proveemos soporte técnico a todos sus equipos de cómputo y seguridad a empresas y hogares en Bogotá.Servicios a domicilio motorizado para un servicio tecnico más rápido.Soporte remoto a sus equipos a computadores portátiles.Soporte tecnico outsourcing para empresas',
          imagen: 'images/contactenos-en-bogota.png',
          categoria: 'soporte'
        },
        {
          title: 'Mantenimiento',
          description: 'Realizamos mantenimiento a todos los equipos de su empresa y hogares en Bogotá.Mantenimiento Correctivo corrige problemas en sus equipos de Cómputo, Redes de voz y datos y cámaras de seguridad.Mantenimiento preventivo corrige y detecta anticipadamente problemas en sus equipos eso permite tener los sistemas siempre activos.',
          imagen: 'images/mantenimiento-a-domicilio.png',
          categoria: 'mantenimiento'
        },
        {
          title: 'Instalación',
          description: 'Instalamos y ponemos en marcha los equipos y sistemas de su empresa y hogares. Como: Computadores, equipos portátiles, impresoras y servidores. Redes de voz y datos, cableado estructurado. Cámaras de seguridad, sistemas de vigilancia y controles de acceso y/o asistencia. Instalación y adecuación de nuevos puestos. de trabajo corriente eléctrica internet y comunicaciones',
          imagen: 'images/instalacion-configuracion.png',
          categoria: 'mantenimiento'
        },
        {
          title: 'Reparacion',
          description: 'Reparación de equipos y computadores en Bogotá a domicilio.Computadores de mesa - desktop, computadores portátiles de todas las marcas.Portátiles personales y corporativos, Servidores.Reparación de daños en redes de voz y datos con cableado estructurado.Reparación de cámaras de seguridad y sistemas de video vigilancia Cctv.Reparación y solución de daños con telefonía fija como líneas telefónicas extensiones y conmutadores telefónicos (plantas telefónicas y teléfonos y consolas secretariales)',
          imagen: 'images/reparacion-y-arreglo.png',
          categoria: 'reparacion'
        },
        {
          title: 'Configuracion',
          description: 'Configuración de equipos de cómputo en Bogotá a domicilio y remotamente.Configuración de toda clase de dispositivos para que se comuniquen con otros equipos dentro de la red y comparta recursos en la red.Configuración de Cámaras de seguridad cctv para poderlas visualizar en dispositivos móviles por internet Configuración de equipos Routers modem servidores e impresoras y sistemas de comunicación como escritorios remotos, vpn Configuración de centrales telefónicas (plantas telefónicas o conmutadores) números de extensión mensaje de bienvenida etc.',
          imagen: '/images/soporte-mantenimiento.png',
          categoria: 'Configuracion'
        },
        {
          title: 'Servicio Tecnico',
          description: 'Contamos con personal calificado que puede diagnosticar y solucionar cualquier falla.Centro de atención al cliente en nuestro PBX (1) 753-7246 donde se brindará la información y se coordinarán los horarios de las visitas técnicas.Personal que ofrece la opción de soporte remoto con seguridad para que sus datos estén protegidos.',
          imagen: 'images/servicio-tecnico-bogota.png',
          categoria: 'Servicio Tecnico'
        }
      ]
    }
  }
}
</script>
<style scoped>
.agencia {
    background: #12AFAF;
    color: #8b8b8b;
    margin-top: 5px;
    background-color: #1074cc3d;
    padding: 1rem;
    border: 1px solid gainsboro;
    border-radius: 1rem;
    box-shadow: 3px -7px 9px -1px black;}
</style>
